import React from 'react';

import { cn } from '@/utils/styles.utils';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Container = (props: Props) => {
  const { children, className } = props;
  return (
    <div className={cn('mx-auto my-0 w-full max-w-screen-desktop px-4 py-0 laptop:px-8', className)}>{children}</div>
  );
};
