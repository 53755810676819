import Image, { ImageProps } from 'next/image';
import React from 'react';

type Size = 'm' | 'l' | 'xl' | 'xxl';

interface Props extends Omit<ImageProps, 'src'> {
  src?: string;
  objectFit?: 'contain' | 'cover';
  objectPosition?: 'top' | 'left' | 'bottom' | 'right';
  size?: Size;
  unoptimized?: boolean;
}

const aspect = 197 / 128;

const widthMap: Record<Size, number> = {
  m: 128,
  l: 256,
  xl: 384,
  xxl: 512,
};

export const TyreImage = (props: Props) => {
  const { src, alt, objectFit = 'contain', size = 'm', objectPosition, style, ...restProps } = props;
  const imageSource = src || '/default-tyre.jpg';
  const imageAlt = alt || 'Domyślna grafika';
  const width = widthMap[size];
  const height = width * aspect;
  return (
    <Image
      src={imageSource}
      alt={imageAlt}
      height={height}
      width={width}
      style={{
        ...style,
        objectFit,
        objectPosition,
      }}
      {...restProps}
    />
  );
};
