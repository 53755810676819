import * as Dialog from '@radix-ui/react-dialog';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { AnimatePresence, m } from 'framer-motion';
import React, { ComponentProps, PropsWithChildren } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { cn } from '@/utils/styles.utils';

import { ModalContext, useModalContext } from './modal.context';

interface ModalProps {
  onOpenChange?: ComponentProps<typeof Dialog.Root>['onOpenChange'];
  isOpen?: ComponentProps<typeof Dialog.Root>['open'];
  children: React.ReactNode;
}

export const ModalContent = ({
  children,
  className,
  contentClassName,
}: PropsWithChildren & { className?: string; contentClassName?: string }) => {
  const { isOpen } = useModalContext();
  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal forceMount>
            <RemoveScroll>
              <m.div
                className={cn(
                  'fixed inset-0 z-[300] grid place-items-center overflow-y-auto bg-black/35 py-6',
                  className,
                )}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1 },
                  collapsed: { opacity: 0 },
                }}
                transition={{ duration: 0.2 }}
              >
                <Dialog.Title hidden />
                <Dialog.Content forceMount className={contentClassName}>
                  {children}
                </Dialog.Content>
              </m.div>
            </RemoveScroll>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </>
  );
};

export const Modal = ({ isOpen, onOpenChange, children }: ModalProps) => {
  const [_isOpen, setIsOpen] = useControllableState({
    defaultProp: false,
    prop: isOpen,
    onChange: onOpenChange,
  });

  return (
    <ModalContext.Provider
      value={{
        isOpen: _isOpen,
      }}
    >
      {/* Radix dialog performance issue - https://github.com/radix-ui/primitives/issues/1634 */}
      {/* Modal need to be set false -> overlay, body scroll lock captured in RemoveScroll from react-remove-scroll  */}
      {/* Custom overlay from react-remove-scroll */}
      {/* Test when radix-dialog update /react-remove-scroll which use react-remove-scroll-bar 2.3.6 */}
      {/* https://github.com/theKashey/react-remove-scroll-bar/commit/7278f9a523e7f007c328fb5b3b9910de63feb80a */}
      {/* If modal is set to false -> could not stack modals */}
      <Dialog.Root open={_isOpen} onOpenChange={setIsOpen} modal={false}>
        {children}
      </Dialog.Root>
    </ModalContext.Provider>
  );
};

export const ModalTrigger = (props: Dialog.DialogTriggerProps) => <Dialog.Trigger asChild {...props} />;
export const ModalClose = (props: Dialog.DialogCloseProps) => <Dialog.Close asChild {...props} />;
