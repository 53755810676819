/* eslint-disable */

/* tslint:disable */
// @ts-nocheck

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export interface Address {
  id: number;
  /** @maxLength 50 */
  first_name?: string;
  /** @maxLength 50 */
  last_name?: string;
  /** @maxLength 255 */
  company_name?: string;
  /** @maxLength 12 */
  vat_number?: string;
  /** @maxLength 255 */
  street?: string;
  /** @maxLength 20 */
  street_number?: string;
  /** @maxLength 20 */
  apartment_number?: string;
  /** @maxLength 255 */
  city?: string;
  /** @maxLength 20 */
  postal_code?: string;
  /** @maxLength 25 */
  phone?: string;
}

export interface AlternativeVariant {
  sku: string;
  production_year: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  type: AlternativeVariantTypeEnum;
}

export type AlternativeVariantTypeEnum = 'newer' | 'cheaper';

export interface BlogCategory {
  /** @maxLength 200 */
  name: string;
  /**
   * @maxLength 200
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  description?: string | null;
  breadcrumbs: Path[];
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
}

export interface BlogCategoryWithPosts {
  /** @maxLength 200 */
  name: string;
  /**
   * @maxLength 200
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  description?: string | null;
  breadcrumbs: Path[];
  posts: PostList[];
}

export interface BlogMainPage {
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  breadcrumbs: Path[];
  blog_new_posts: PostList[];
  blog_categories: BlogCategoryWithPosts[];
}

export interface BooleanDetailFilter {
  value: boolean;
  name: string;
  count: number;
}

export interface BooleanFilter {
  filter_type: BooleanFilterFilterTypeEnum;
  filter_name: string;
  filter_id: string;
  values: BooleanDetailFilter[];
}

export type BooleanFilterFilterTypeEnum = 'boolean';

export interface CarCategory {
  h1: string;
  meta_title: string;
  meta_description: string;
  description: string;
  breadcrumbs: Path[];
  internal_links: InternalLink[];
  faq: FAQ[];
  makes: MakeMainCategory[];
}

export interface CarModelCategory {
  /** @maxLength 255 */
  shop_slug?: string;
  /** @maxLength 255 */
  shop_name?: string;
}

export interface CarSizeDetail {
  size: string;
  load_index: string;
  speed_index: string;
  rim: number;
  filters: Record<string, any[]>;
}

export interface CarSizes {
  id: string;
  front: CarSizeDetail;
  rear?: CarSizeDetail;
}

export interface Category {
  category: CategoryDetail;
  products: ProductCategory;
  similar_products_label?: string;
  similar_products?: ListingVariant[];
}

export interface CategoryDetail {
  product_filters: Record<string, string>;
  /** @maxLength 255 */
  absolute_url: string;
  type: CategoryDetailTypeEnum;
  /** @maxLength 255 */
  h1: string;
  description?: string | null;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  breadcrumbs: Path[];
  internal_links: InternalLink[];
  faq: FAQ[];
}

export type CategoryDetailTypeEnum = 'tyre_tread_page' | 'tyre_listing';

export interface CategoryFilters {
  category_filters: Record<string, Record<string, string>>;
}

export interface ChangePassword {
  old_password: string;
  /** @maxLength 128 */
  password: string;
}

export interface Checkout {
  /** @format uuid */
  token: string;
  lines_count: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_gross_amount: string;
  lines: CheckoutLine[];
}

export interface CheckoutCampaign {
  /** @maxLength 255 */
  name: string;
  /**
   * @maxLength 18
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  listing_label_color?: string;
  /** @maxLength 255 */
  listing_label?: string;
}

export interface CheckoutLine {
  /** @format uuid */
  token: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** @maxLength 255 */
  sku: string;
  variant: CheckoutVariant;
  /**
   * @min 1
   * @max 12
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price_gross_amount: string;
}

export interface CheckoutProduct {
  /**
   * @maxLength 13
   * @pattern ^[0-9]{13}$
   */
  ean: string;
  /** @maxLength 255 */
  shop_name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 30 */
  tyre_type: string;
  /** @maxLength 30 */
  season: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 30 */
  width: string;
  /** @maxLength 30 */
  profile: string;
  /** @maxLength 30 */
  rim: string;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 10 */
  label_fuel?: string | null;
  /** @maxLength 10 */
  label_break?: string | null;
  /** @maxLength 10 */
  label_sound_decibels?: string | null;
  /** @maxLength 10 */
  label_sound_level?: string | null;
  /** @maxLength 10 */
  xl?: string | null;
  /** @maxLength 10 */
  fr?: string | null;
  /** @maxLength 10 */
  cargo?: string | null;
  /** @maxLength 10 */
  pmsf?: string | null;
  /** @maxLength 30 */
  run_flat?: string | null;
  /** @maxLength 255 */
  selfseal_technology?: string | null;
  /** @maxLength 255 */
  noice_cancelling_system?: string | null;
  /** @maxLength 255 */
  homologation?: string | null;
  /** @maxLength 255 */
  homologation_long?: string | null;
  /** @maxLength 10 */
  ice?: string | null;
  /** @maxLength 30 */
  eprel?: string | null;
  images: ListingProductImage[];
  producer_attr: ProducerAttr;
  tread_attr: ListingTreadAttr;
}

export interface CheckoutVariant {
  /** @maxLength 255 */
  sku: string;
  product: CheckoutProduct;
  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  old_price?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sold?: number | null;
  /** @maxLength 100 */
  delivery_time: string;
  delivery_date: string;
  /** @maxLength 100 */
  production_year?: string | null;
  is_promoted?: boolean;
  is_promoted_text?: string;
  is_main?: boolean;
  has_promotion_label?: boolean;
  has_new_label?: boolean;
  campaigns: CheckoutCampaign[];
}

export interface CreateCheckout {
  /** @maxLength 255 */
  sku: string;
  /**
   * @min 1
   * @max 12
   */
  quantity: number;
}

export interface CreateOrder {
  /**
   * @format email
   * @maxLength 254
   */
  user_email: string;
  payment_method: PaymentMethodEnum;
  /**
   * P24 external payment id.
   * @min 0
   * @max 2147483647
   */
  method_id?: number | null;
  /** @maxLength 36 */
  checkout_token?: string;
  /** @maxLength 50 */
  shipping_first_name?: string;
  /** @maxLength 50 */
  shipping_last_name?: string;
  /** @maxLength 100 */
  shipping_company_name?: string;
  /** @maxLength 70 */
  shipping_street: string;
  /** @maxLength 15 */
  shipping_street_number?: string;
  /** @maxLength 15 */
  shipping_apartment_number?: string;
  /** @maxLength 100 */
  shipping_city: string;
  /** @maxLength 20 */
  shipping_postal_code: string;
  /** @maxLength 25 */
  phone_number: string;
  /** @maxLength 50 */
  billing_first_name?: string;
  /** @maxLength 50 */
  billing_last_name?: string;
  /** @maxLength 200 */
  billing_company_name?: string;
  /** @maxLength 12 */
  billing_vat_number?: string;
  /** @maxLength 70 */
  billing_street: string;
  /** @maxLength 15 */
  billing_street_number?: string;
  /** @maxLength 15 */
  billing_apartment_number?: string;
  /** @maxLength 100 */
  billing_city: string;
  /** @maxLength 20 */
  billing_postal_code: string;
  /** @maxLength 255 */
  user_comment?: string;
  /** @maxLength 50 */
  registration_number?: string;
  marketing_agreement?: boolean;
}

export interface CreateOrderResponse {
  /** @format uuid */
  id: string;
  /**
   * @format uri
   * @maxLength 200
   */
  payment_url?: string | null;
  number: number;
}

export interface Description {
  sections?: DescriptionItems[];
}

export interface DescriptionField {
  type: DescriptionFieldTypeEnum;
  content?: string;
}

export type DescriptionFieldTypeEnum = 'TEXT' | 'IMAGE';

export interface DescriptionItems {
  items?: DescriptionField[];
}

export interface Email {
  /** @format email */
  email: string;
}

export interface Engine {
  make_slug: string;
  model_slug: string;
  modification_slug: string;
  generation_slug: string;
  engine: string;
}

export interface FAQ {
  question: string;
  answer: string;
}

export interface Footer {
  categories: GroupPath;
  producers: GroupPath;
}

export interface Generation {
  generation_shop_name: string;
  generation_slug: string;
}

export interface GroupMultiSelectDetailFilter {
  group: string;
  count: number;
  values: MultiSelectDetailFilter[];
}

export interface GroupMultiSelectFilter {
  filter_type: GroupMultiSelectFilterFilterTypeEnum;
  filter_name: string;
  filter_id: string;
  groups: GroupMultiSelectDetailFilter[];
}

export type GroupMultiSelectFilterFilterTypeEnum = 'group_multi_select';

export interface GroupPath {
  group_name: string;
  values: Path[];
}

export interface GroupSiteMap {
  group_name: string;
  values: SiteMap[];
}

export interface InternalLink {
  group_name: string;
  values: Path[];
}

export interface ListingBanner {
  /** @maxLength 255 */
  name: string;
  /**
   * (1174 x 120) Listing desktop banner
   * @format uri
   */
  listing_desktop_banner?: string | null;
  /**
   * (1174 x 120) Listing tablet banner - można dać taki sam jak desktop
   * @format uri
   */
  listing_tablet_banner?: string | null;
  /**
   * (400 x 120) Listing mobile banner
   * @format uri
   */
  listing_mobile_banner?: string | null;
  /** @maxLength 255 */
  banner_label?: string;
  /**
   * Adres url kampanii. Wprowadzamy tylko /kapmpania-nokian
   * @maxLength 100
   */
  path?: string;
  /**
   * Wprowadź url do loga, które ma być wyświetlone. URL możesz wziąć z Producers
   * @format uri
   * @maxLength 255
   */
  logo?: string | null;
}

export interface ListingCampaign {
  /** @maxLength 255 */
  name: string;
  /**
   * @maxLength 18
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  listing_label_color?: string;
  /** @maxLength 255 */
  listing_label?: string;
}

export interface ListingProduct {
  /** @maxLength 255 */
  shop_name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 30 */
  tyre_type: string;
  /** @maxLength 30 */
  season: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 30 */
  width: string;
  /** @maxLength 30 */
  profile: string;
  /** @maxLength 30 */
  rim: string;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 150 */
  load_index_long: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 150 */
  speed_index_long: string;
  /** @maxLength 10 */
  label_fuel?: string | null;
  /** @maxLength 10 */
  label_break?: string | null;
  /** @maxLength 10 */
  label_sound_decibels?: string | null;
  /** @maxLength 10 */
  label_sound_level?: string | null;
  /** @maxLength 10 */
  xl?: string | null;
  /** @maxLength 10 */
  fr?: string | null;
  /** @maxLength 10 */
  cargo?: string | null;
  /** @maxLength 10 */
  pmsf?: string | null;
  /** @maxLength 30 */
  run_flat?: string | null;
  /** @maxLength 255 */
  selfseal_technology?: string | null;
  /** @maxLength 255 */
  noice_cancelling_system?: string | null;
  /** @maxLength 255 */
  homologation?: string | null;
  /** @maxLength 255 */
  homologation_long?: string | null;
  /** @maxLength 10 */
  ice?: string | null;
  images: ListingProductImage[];
  producer_attr: ProducerAttr;
  tread_attr: ListingTreadAttr;
}

export interface ListingProductImage {
  /** @format uri */
  url: string;
  /** @maxLength 255 */
  alt: string;
  is_label?: boolean;
}

export interface ListingTreadAttr {
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating: string | null;
  rating_count: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_comfort: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_dry_surface: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_wet_surface: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_winter_conditions: string | null;
}

export interface ListingVariant {
  /** @maxLength 255 */
  sku: string;
  product: ListingProduct;
  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  old_price?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sold?: number | null;
  /** @maxLength 100 */
  delivery_time: string;
  delivery_date: string;
  /** @maxLength 100 */
  production_year?: string | null;
  is_promoted?: boolean;
  is_promoted_text?: string;
  is_main?: boolean;
  has_promotion_label?: boolean;
  has_new_label?: boolean;
  campaigns: ListingCampaign[];
}

export interface MainPage {
  main_page_reviews: MainPageReviewSection;
  main_page_tyre_models: MainPageTyreModel[];
  main_page_posts: MainPagePost[];
  main_page_producers: MainPageProducer[];
  main_page_meta_title: string;
  main_page_meta_description: string;
}

export interface MainPageBanner {
  /** @maxLength 255 */
  name: string;
  /**
   * (2000 x 580) Tło banera desktop na stronie głównej
   * @format uri
   */
  main_page_desktop_banner?: string | null;
  /**
   * (1174 x 580) Content banera desktop na stronie głównej
   * @format uri
   */
  main_page_desktop_banner_content?: string | null;
  /**
   * (1000 x 500) Tło banera tablet na stronie głównej
   * @format uri
   */
  main_page_tablet_banner?: string | null;
  /**
   * (640 x 500) Content banera tablet na stronie głównej
   * @format uri
   */
  main_page_tablet_banner_content?: string | null;
  /**
   * (768 x 480) Tło banera mobile na stronie głównej
   * @format uri
   */
  main_page_mobile_banner?: string | null;
  /**
   * (320 x 480) Content banera mobile na stronie głównej
   * @format uri
   */
  main_page_mobile_banner_content?: string | null;
  /** @maxLength 255 */
  banner_label?: string;
  /**
   * Adres url kampanii. Wprowadzamy tylko /kapmpania-nokian
   * @maxLength 100
   */
  path?: string;
  /**
   * Wprowadź url do loga, które ma być wyświetlone. URL możesz wziąć z Producers
   * @format uri
   * @maxLength 255
   */
  logo?: string | null;
}

export interface MainPagePost {
  category_name: string;
  /** @maxLength 200 */
  title: string;
  /** @format date-time */
  created_at: string;
  /** @format uri */
  image?: string | null;
  /**
   * in minutes
   * @min -2147483648
   * @max 2147483647
   */
  read_time?: number;
  path: string;
}

export interface MainPageProducer {
  /** @maxLength 255 */
  name: string;
  path: string;
  /** @format uri */
  logo?: string | null;
}

export interface MainPageReview {
  user_alias: string;
  description: string;
}

export interface MainPageReviewSection {
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  rating: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  rating_description: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  rating_customer: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  rating_delivery: string;
  reviews: MainPageReview[];
}

export interface MainPageSeasons {
  value: string;
  name: string;
}

export interface MainPageTyreModel {
  producer: string;
  tread: string;
  season: SeasonEnum;
  path: string;
  image: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cheapest_price: string;
}

export interface Make {
  slug: string;
  /** @maxLength 255 */
  shop_name?: string;
  is_popular?: boolean;
}

export interface MakeCategory {
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  shop_slug: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  shop_name: string;
  /**
   * @format uri
   * @maxLength 200
   */
  logo?: string | null;
  /** @maxLength 255 */
  h1: string;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  description?: string | null;
  breadcrumbs: Path[];
  internal_links: InternalLink[];
  faq: FAQ[];
  models: CarModelCategory[];
}

export interface MakeMainCategory {
  /** @maxLength 255 */
  shop_slug?: string;
  /** @maxLength 255 */
  shop_name?: string;
  /**
   * @format uri
   * @maxLength 200
   */
  logo?: string | null;
}

export interface Media {
  images: ProductImage[];
  images_360: Tread360Image[];
  videos: TreadVideo[];
}

export interface Menu {
  menu_path: string;
  seasons: GroupPath;
  tyre_types: GroupPath;
  producers: GroupPath;
  popular_sizes: GroupPath;
  popular_models: GroupPath;
  car_makes: GroupPath;
}

export interface MenuSummer {
  menu_path: string;
  summer_producers: GroupPath;
  summer_sizes: GroupPath;
  summer_rims: GroupPath;
}

export interface MenuWinter {
  menu_path: string;
  winter_producers: GroupPath;
  winter_sizes: GroupPath;
  winter_rims: GroupPath;
}

export interface Model {
  slug: string;
  /** @maxLength 255 */
  shop_name?: string;
  years: string[];
}

export interface ModelCategory {
  make_logo: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  make_slug: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  model_slug: string;
  /** @maxLength 255 */
  shop_name?: string;
  /** @maxLength 255 */
  h1: string;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  description?: string | null;
  breadcrumbs: Path[];
  internal_links: InternalLink[];
  faq: FAQ[];
  generations: ModificationGroup[];
}

export interface Modification {
  shop_name: string;
  /** @maxLength 255 */
  generation_name?: string | null;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  modification_slug: string;
  /** @maxLength 255 */
  generation_slug: string;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  start_year: number;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  end_year: number;
}

export interface ModificationGroup {
  group_name: string;
  values: Modification[];
}

export interface MultiSelectDetailFilter {
  value: string;
  name: string;
  count: number;
}

export interface MultiSelectFilter {
  filter_type: MultiSelectFilterFilterTypeEnum;
  filter_name: string;
  filter_id: string;
  values: MultiSelectDetailFilter[];
}

export type MultiSelectFilterFilterTypeEnum = 'multi_select';

export interface Order {
  /**
   * @format email
   * @maxLength 254
   */
  user_email: string;
  payment_method: PaymentMethodEnum;
  /**
   * P24 external payment id.
   * @min 0
   * @max 2147483647
   */
  method_id?: number | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_gross_amount?: string;
  /** @maxLength 36 */
  checkout_token?: string;
  /** @maxLength 50 */
  shipping_first_name?: string;
  /** @maxLength 50 */
  shipping_last_name?: string;
  /** @maxLength 100 */
  shipping_company_name?: string;
  /** @maxLength 70 */
  shipping_street: string;
  /** @maxLength 15 */
  shipping_street_number?: string;
  /** @maxLength 15 */
  shipping_apartment_number?: string;
  /** @maxLength 100 */
  shipping_city: string;
  /** @maxLength 20 */
  shipping_postal_code: string;
  /** @maxLength 25 */
  phone_number: string;
  /** @maxLength 50 */
  billing_first_name?: string;
  /** @maxLength 50 */
  billing_last_name?: string;
  /** @maxLength 200 */
  billing_company_name?: string;
  /** @maxLength 12 */
  billing_vat_number?: string;
  /** @maxLength 70 */
  billing_street: string;
  /** @maxLength 15 */
  billing_street_number?: string;
  /** @maxLength 15 */
  billing_apartment_number?: string;
  /** @maxLength 100 */
  billing_city: string;
  /** @maxLength 20 */
  billing_postal_code: string;
  /** @maxLength 255 */
  user_comment?: string;
  /** @maxLength 50 */
  registration_number?: string;
  marketing_agreement?: boolean;
  lines: OrderLine[];
  number: number;
  /** @format uri */
  payment_url: string | null;
  /** @format uuid */
  id: string;
  is_paid: boolean;
  is_cod: boolean;
  delivery_date: string;
}

export interface OrderLine {
  /** @maxLength 255 */
  sku: string;
  /** @maxLength 255 */
  ean: string;
  /** @maxLength 255 */
  production_year?: string;
  /**
   * @min 1
   * @max 12
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price_gross_amount?: string;
}

export interface PaginatedListingVariantList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: ListingVariant[];
}

export interface PaginatedPostListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: PostList[];
}

export interface PaginatedReviewList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: Review[];
}

export interface PaginatedReviewsResponse {
  count: number;
  /** @format uri */
  next?: string | null;
  /** @format uri */
  previous?: string | null;
  results: Review[];
}

export interface PaginatedUserOrderListList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: UserOrderList[];
}

export interface PasswordToken {
  password: string;
  token: string;
}

export interface Path {
  name: string;
  path: string;
}

export interface PaymentMethod {
  id: number;
  payment_method: PaymentMethodEnum;
  method_id: number;
  name: string;
  description: string;
}

export type PaymentMethodEnum = 'przelewy24' | 'blik' | 'paypo' | 'cod';

export interface Post {
  category_name: string;
  /** @format uri */
  image?: string | null;
  /** @maxLength 200 */
  title: string;
  content: Description;
  /**
   * in minutes
   * @min -2147483648
   * @max 2147483647
   */
  read_time?: number;
  /** @format date-time */
  created_at: string;
  breadcrumbs?: Record<string, any>;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  /** @format date-time */
  updated_at: string;
}

export interface PostList {
  category_name: string;
  category_slug: string;
  /** @format uri */
  image?: string | null;
  /** @maxLength 200 */
  title: string;
  /**
   * @maxLength 200
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /**
   * in minutes
   * @min -2147483648
   * @max 2147483647
   */
  read_time?: number;
  /** @format date-time */
  created_at: string;
}

export interface ProducerAttr {
  /** @maxLength 255 */
  tyre_class: string;
  /** @maxLength 255 */
  warranty?: string;
}

export interface Product {
  /** @maxLength 255 */
  shop_name: string;
  /** @maxLength 255 */
  name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 255 */
  code?: string | null;
  /**
   * @maxLength 13
   * @pattern ^[0-9]{13}$
   */
  ean: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 30 */
  tyre_type: string;
  /** @maxLength 30 */
  season: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 30 */
  width: string;
  /** @maxLength 30 */
  profile: string;
  /** @maxLength 30 */
  rim: string;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 150 */
  load_index_long: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 150 */
  speed_index_long: string;
  /** @maxLength 10 */
  label_fuel?: string | null;
  /** @maxLength 10 */
  label_break?: string | null;
  /** @maxLength 10 */
  label_sound_decibels?: string | null;
  /** @maxLength 10 */
  label_sound_level?: string | null;
  /** @maxLength 10 */
  xl?: string | null;
  /** @maxLength 10 */
  fr?: string | null;
  /** @maxLength 10 */
  cargo?: string | null;
  /** @maxLength 10 */
  pmsf?: string | null;
  /** @maxLength 30 */
  run_flat?: string | null;
  /** @maxLength 255 */
  selfseal_technology?: string | null;
  /** @maxLength 255 */
  noice_cancelling_system?: string | null;
  /** @maxLength 255 */
  homologation?: string | null;
  /** @maxLength 255 */
  homologation_long?: string | null;
  /** @maxLength 10 */
  ice?: string | null;
  images: ProductImage[];
  media: Media;
  breadcrumbs: Path[];
  tread_attr: ProductTreadAttr;
  producer_attr: ProductProducerAttr;
  internal_links?: Record<string, any>;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  category_links: Record<string, any>;
}

export interface ProductCategory {
  count: number;
  /** @format uri */
  next: string;
  /** @format uri */
  previous: string;
  results: ListingVariant[];
}

export interface ProductImage {
  /** @format uri */
  url: string;
  /** @maxLength 255 */
  alt: string;
  is_label?: boolean;
}

export interface ProductInquiry {
  sku?: string;
  ean: string;
  /** @format email */
  user_email: string;
  message: string;
}

export interface ProductProducerAttr {
  /** @maxLength 255 */
  tyre_class: string;
  /** @maxLength 255 */
  warranty?: string;
  /** @maxLength 255 */
  warranty_text?: string;
  warranty_description: string[];
  /** @format uri */
  warranty_file?: string | null;
  /** @format uri */
  certificate_file?: string | null;
  /** @format uri */
  logo?: string | null;
}

export interface ProductReview {
  /**
   * @maxLength 13
   * @pattern ^[0-9]{13}$
   */
  ean: string;
  /** @maxLength 255 */
  shop_name: string;
  season: SeasonEnum;
}

export interface ProductSales {
  /** @maxLength 255 */
  user: string;
  /** @format date-time */
  date: string;
}

export interface ProductTreadAttr {
  /** @maxLength 255 */
  name: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_comfort: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_dry_surface: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_wet_surface: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating_winter_conditions: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating: string | null;
  rating_count: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  rating_recommend: string | null;
  description: Description;
  rating_stars_count?: Record<string, any>;
  reviews: PaginatedReviewsResponse;
}

export interface RangeFilter {
  filter_type: RangeFilterFilterTypeEnum;
  filter_name: string;
  filter_id: string;
  values: RangeFilterDetail;
}

export interface RangeFilterDetail {
  /** @format double */
  min: number;
  /** @format double */
  max: number;
}

export type RangeFilterFilterTypeEnum = 'range';

export interface Redirect {
  old_path: string;
  new_path: string;
}

export interface Registration {
  /** @maxLength 255 */
  first_name?: string;
  /** @maxLength 255 */
  last_name?: string;
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 128 */
  password: string;
  marketing_agreement?: boolean;
}

export interface RegonAPISearchResult {
  billing_company_name: string;
  billing_street: string;
  billing_street_number: string;
  billing_apartment_number: string;
  billing_city: string;
  billing_postal_code: string;
}

export interface ResetToken {
  token: string;
}

export interface Review {
  /** @format uuid */
  uuid: string;
  /** @maxLength 100 */
  user_alias: string;
  /** @maxLength 1000 */
  description: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  comfort: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  dry_surface: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  wet_surface: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  winter_conditions?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  rating: string;
  /** @format uri */
  image?: string | null;
  is_recommended?: boolean;
  /** Czy opinia jest potwierdzona zakupem? */
  is_purchase_confirmed?: boolean;
  /** @format date-time */
  created_at: string;
  size: string;
  images: ReviewImage[];
}

export interface ReviewCreate {
  /** @maxLength 100 */
  user_alias: string;
  /** @maxLength 1000 */
  description: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  comfort: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  dry_surface: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  wet_surface: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,1})?$
   */
  winter_conditions?: string | null;
  is_recommended?: boolean;
  images?: string[];
}

export interface ReviewImage {
  /** @format uri */
  image: string;
  alt: string;
}

// Need to be manually added - swagger-typescript-api codegen does not support it
export type SearchBarFilters = MultiSelectFilter | RangeFilter | BooleanFilter | GroupMultiSelectFilter;

export type SearchBarPositionEnum = 'top' | 'bottom';

export type SeasonEnum = 'zimowe' | 'letnie' | 'całoroczne';

export interface Settings {
  /** @maxLength 20 */
  monday_friday_start?: string;
  /** @maxLength 20 */
  monday_friday_end?: string;
  /** @maxLength 20 */
  saturday_start?: string;
  /** @maxLength 20 */
  saturday_end?: string;
  /** Ustawiamy, które bieżniki mają być promowane na stronie głównej. */
  promoted_treads_main_page?: Record<string, any>;
}

export interface SimilarProduct {
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 10 */
  label_fuel?: string | null;
  /** @maxLength 10 */
  label_break?: string | null;
  /** @maxLength 10 */
  label_sound_decibels?: string | null;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 150 */
  load_index_long: string;
  /** @maxLength 150 */
  speed_index_long: string;
  /**
   * @format decimal
   * @default "0.00"
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  rating?: string;
  images: ProductImage[];
}

export interface SimilarVariant {
  /** @maxLength 255 */
  sku: string;
  /** @maxLength 100 */
  production_year?: string | null;
  product: SimilarProduct;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  old_price?: string | null;
  is_main?: boolean;
}

export interface SimpleCheckout {
  /** @format uuid */
  token: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_gross_amount?: string;
  /**
   * @min 0
   * @max 2147483647
   */
  lines_count?: number;
}

export interface SiteMap {
  name: string;
  path: string;
}

export interface SizeByCar {
  car_name: string;
  car_specification: string;
  car_image: string;
  make: string;
  model: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  make_slug: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  model_slug: string;
  year: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  generation_slug: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  modification_slug: string;
  sizes: CarSizes[];
}

export interface Sizes {
  main_page_seasons: MainPageSeasons[];
  sizes: Record<string, string[]>;
  widths: string[];
  profiles_by_width: Record<string, string[]>;
  rims_by_width_and_profile: Record<string, Record<string, string>[]>;
}

export interface StaticPage {
  /** @maxLength 255 */
  name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 255 */
  h1?: string | null;
  content: Description;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  breadcrumbs?: Record<string, any>;
  is_seo_indexed?: boolean;
  search_bar_position?: SearchBarPositionEnum;
  /** @maxLength 255 */
  search_bar_title?: string | null;
  search_bar_filters?: Record<string, any>;
}

export type Status731Enum = 'new' | 'in_realization' | 'sent' | 'delivered' | 'canceled';

export interface TokenObtainPair {
  email: string;
  password: string;
  access: string;
  refresh: string;
}

export interface TokenRefresh {
  access: string;
  refresh: string;
}

export interface TokenVerify {
  token: string;
}

export interface Tread360Image {
  /** @maxLength 255 */
  key: string;
  /** @maxLength 255 */
  src: string;
}

export interface TreadVideo {
  /**
   * @format uri
   * @maxLength 255
   */
  url: string;
}

export interface TyreModelAvailableProduct {
  /** @maxLength 255 */
  shop_name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 30 */
  season: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 30 */
  width: string;
  /** @maxLength 30 */
  profile: string;
  /** @maxLength 30 */
  rim: string;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 10 */
  xl?: string | null;
  /** @maxLength 10 */
  fr?: string | null;
  /** @maxLength 10 */
  cargo?: string | null;
  /** @maxLength 30 */
  run_flat?: string | null;
  /** @maxLength 255 */
  selfseal_technology?: string | null;
  /** @maxLength 255 */
  noice_cancelling_system?: string | null;
  /** @maxLength 255 */
  homologation?: string | null;
}

export interface TyreModelAvailableVariant {
  /** @maxLength 255 */
  sku: string;
  product: TyreModelAvailableProduct;
  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  old_price?: string | null;
  /** @maxLength 100 */
  delivery_time: string;
  delivery_date: string;
  /** @maxLength 100 */
  production_year?: string | null;
  is_main?: boolean;
}

export interface TyreModelCategory {
  /** @maxLength 255 */
  h1: string;
  description: string;
  /** @maxLength 60 */
  meta_title?: string | null;
  /** @maxLength 160 */
  meta_description?: string | null;
  breadcrumbs: Path[];
  internal_links: InternalLink[];
  faq: Record<string, string>[];
}

export interface TyreModelImage {
  /** @format uri */
  url: string;
  alt: string;
  /** @default false */
  is_label?: boolean;
}

export interface TyreModelMedia {
  images: TyreModelImage;
  images_360: Tread360Image[];
  videos: TreadVideo[];
}

export interface TyreModelProduct {
  /** @format double */
  cheapest_variant: number;
  /** @format double */
  most_expensive_variant: number;
  variants_count: number;
  /** @maxLength 255 */
  name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 30 */
  tyre_type: string;
  /** @maxLength 30 */
  season: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 30 */
  width: string;
  /** @maxLength 30 */
  profile: string;
  /** @maxLength 30 */
  rim: string;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 150 */
  load_index_long: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 150 */
  speed_index_long: string;
  /** @maxLength 10 */
  label_fuel?: string | null;
  /** @maxLength 10 */
  label_break?: string | null;
  /** @maxLength 10 */
  label_sound_decibels?: string | null;
  /** @maxLength 10 */
  label_sound_level?: string | null;
  /** @maxLength 10 */
  xl?: string | null;
  /** @maxLength 10 */
  fr?: string | null;
  /** @maxLength 10 */
  cargo?: string | null;
  /** @maxLength 10 */
  pmsf?: string | null;
  /** @maxLength 30 */
  run_flat?: string | null;
  /** @maxLength 255 */
  selfseal_technology?: string | null;
  /** @maxLength 255 */
  noice_cancelling_system?: string | null;
  /** @maxLength 255 */
  homologation?: string | null;
  /** @maxLength 255 */
  homologation_long?: string | null;
  /** @maxLength 10 */
  ice?: string | null;
  /** @maxLength 30 */
  eprel?: string | null;
  images: TyreModelImage[];
  media: TyreModelMedia;
  breadcrumbs: Path[];
  tread_attr: ProductTreadAttr;
  producer_attr: ProductProducerAttr;
  category_links: Record<string, any>;
}

export interface TyreModelResponse {
  category: TyreModelCategory;
  product: TyreModelProduct;
  search_bar: Sizes;
  variants: TyreModelAvailableVariant[];
}

export interface User {
  /** @maxLength 255 */
  first_name?: string;
  /** @maxLength 255 */
  last_name?: string;
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  marketing_agreement?: boolean;
  default_shipping_address: Address;
  default_billing_address: Address;
  password: string;
  re_password: string;
  addresses: Address[];
}

export interface UserEdit {
  /** @maxLength 255 */
  first_name?: string;
  /** @maxLength 255 */
  last_name?: string;
  marketing_agreement?: boolean;
}

export interface UserOrderDetail {
  /** @format uuid */
  id: string;
  number: number;
  /**
   * Id zamówienia z systemu zamówień.
   * @maxLength 255
   */
  order_system_id?: string;
  /** @format date-time */
  created_at: string;
  status?: Status731Enum;
  is_cod?: boolean;
  is_paid?: boolean;
  payment_method: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_gross_amount?: string;
  /** @maxLength 50 */
  shipping_first_name?: string;
  /** @maxLength 50 */
  shipping_last_name?: string;
  /** @maxLength 100 */
  shipping_company_name?: string;
  /** @maxLength 70 */
  shipping_street: string;
  /** @maxLength 15 */
  shipping_street_number?: string;
  /** @maxLength 15 */
  shipping_apartment_number?: string;
  /** @maxLength 100 */
  shipping_city: string;
  /** @maxLength 20 */
  shipping_postal_code: string;
  /** @maxLength 50 */
  billing_first_name?: string;
  /** @maxLength 50 */
  billing_last_name?: string;
  /** @maxLength 200 */
  billing_company_name?: string;
  /** @maxLength 12 */
  billing_vat_number?: string;
  /** @maxLength 70 */
  billing_street: string;
  /** @maxLength 15 */
  billing_street_number?: string;
  /** @maxLength 15 */
  billing_apartment_number?: string;
  /** @maxLength 100 */
  billing_city: string;
  /** @maxLength 20 */
  billing_postal_code: string;
  /** @maxLength 50 */
  registration_number?: string;
  pdf_invoice: string;
  lines: UserOrderLine[];
}

export interface UserOrderLine {
  /** @maxLength 255 */
  sku: string;
  status?: UserOrderLineStatusEnum;
  /** @maxLength 255 */
  product_name: string;
  /** @maxLength 255 */
  production_year?: string;
  /** @maxLength 255 */
  tracking_link?: string;
  /** @format date */
  delivery_date?: string | null;
  /**
   * @min 1
   * @max 12
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price_gross_amount?: string;
  product: UserOrderLineProduct;
}

export interface UserOrderLineProduct {
  /** @maxLength 255 */
  shop_name: string;
  /**
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
  /** @maxLength 100 */
  producer: string;
  /** @maxLength 30 */
  tyre_type: string;
  /** @maxLength 30 */
  season: string;
  /** @maxLength 100 */
  tread: string;
  /** @maxLength 30 */
  size: string;
  /** @maxLength 30 */
  width: string;
  /** @maxLength 30 */
  profile: string;
  /** @maxLength 30 */
  rim: string;
  /** @maxLength 30 */
  load_index: string;
  /** @maxLength 30 */
  speed_index: string;
  /** @maxLength 10 */
  label_fuel?: string | null;
  /** @maxLength 10 */
  label_break?: string | null;
  /** @maxLength 10 */
  label_sound_decibels?: string | null;
  /** @maxLength 10 */
  label_sound_level?: string | null;
  /** @maxLength 10 */
  xl?: string | null;
  /** @maxLength 10 */
  fr?: string | null;
  /** @maxLength 10 */
  cargo?: string | null;
  /** @maxLength 10 */
  pmsf?: string | null;
  /** @maxLength 30 */
  run_flat?: string | null;
  /** @maxLength 255 */
  selfseal_technology?: string | null;
  /** @maxLength 255 */
  noice_cancelling_system?: string | null;
  /** @maxLength 255 */
  homologation?: string | null;
  /** @maxLength 255 */
  homologation_long?: string | null;
  /** @maxLength 10 */
  ice?: string | null;
  /** @maxLength 30 */
  eprel?: string | null;
  images: ListingProductImage[];
  producer_attr: ProducerAttr;
  tread_attr: ListingTreadAttr;
}

export type UserOrderLineStatusEnum = 'new' | 'packed' | 'sent' | 'delivered' | 'canceled';

export interface UserOrderList {
  /** @format uuid */
  id: string;
  number: number;
  /**
   * Id zamówienia z systemu zamówień.
   * @maxLength 255
   */
  order_system_id?: string;
  /** @format date-time */
  created_at: string;
  status?: Status731Enum;
  is_cod?: boolean;
  is_paid?: boolean;
  payment_method: string;
  lines_count: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_gross_amount?: string;
}

export interface Variant {
  /** @maxLength 255 */
  sku: string;
  product: Product;
  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  old_price?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sold?: number | null;
  /** @maxLength 100 */
  delivery_time: string;
  delivery_date: string;
  /** @maxLength 100 */
  production_year?: string | null;
  is_main?: boolean;
  has_promotion_label?: boolean;
  has_new_label?: boolean;
  is_promoted?: boolean;
  variants_count: number;
  /** @default 0 */
  similar_products_count?: number;
  campaigns: VariantCampaign[];
  similar_variants: SimilarVariant[];
  alternative_variant: AlternativeVariant;
}

export interface VariantCampaign {
  /** @maxLength 255 */
  name: string;
  /**
   * @maxLength 18
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  listing_label_color?: string;
  /** @maxLength 255 */
  listing_label?: string;
  /**
   * (1174 x 250) Product desktop banner
   * @format uri
   */
  product_desktop_banner?: string | null;
  /**
   * (1174 x 250) Product tablet banner - można dać taki sam jak desktop
   * @format uri
   */
  product_tablet_banner?: string | null;
  /**
   * (400 x 200) Product mobile banner
   * @format uri
   */
  product_mobile_banner?: string | null;
  product_banner_content?: string;
}

export namespace Api {
  /**
   * No description
   * @tags account
   * @name AccountOrdersList
   * @request GET:/api/v1/account/orders
   * @secure
   */
  export namespace AccountOrdersList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Number of results to return per page. */
      limit?: number;
      /** The initial index from which to return the results. */
      offset?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedUserOrderListList;
  }

  /**
   * No description
   * @tags account
   * @name AccountOrdersRetrieve
   * @request GET:/api/v1/account/orders/{id}
   * @secure
   */
  export namespace AccountOrdersRetrieve {
    export type RequestParams = {
      /** @format uuid */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserOrderDetail;
  }

  /**
   * No description
   * @tags account
   * @name AccountOrdersInvoiceRetrieve
   * @request GET:/api/v1/account/orders/{id}/invoice
   * @secure
   */
  export namespace AccountOrdersInvoiceRetrieve {
    export type RequestParams = {
      /** @format uuid */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags account
   * @name AccountUserRetrieve
   * @request GET:/api/v1/account/user
   * @secure
   */
  export namespace AccountUserRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }

  /**
   * @description Zmiana hasła użytkownika.
   * @tags account
   * @name AccountUserChangePasswordCreate
   * @request POST:/api/v1/account/user/change_password
   * @secure
   */
  export namespace AccountUserChangePasswordCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ChangePassword;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * @description Usuwanie konta użytkownika.
   * @tags account
   * @name AccountUserDeleteAccountDestroy
   * @request DELETE:/api/v1/account/user/delete_account
   * @secure
   */
  export namespace AccountUserDeleteAccountDestroy {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * @description Aktualizacja profilu użytkownika.
   * @tags account
   * @name AccountUserEditProfileUpdate
   * @request PUT:/api/v1/account/user/edit_profile
   * @secure
   */
  export namespace AccountUserEditProfileUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserEdit;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
   * @tags auth
   * @name AuthLoginCreate
   * @request POST:/api/v1/auth/login
   */
  export namespace AuthLoginCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenObtainPair;
    export type RequestHeaders = {};
    export type ResponseBody = TokenObtainPair;
  }

  /**
   * @description Request a password reset link to be sent to the provided email.
   * @tags auth
   * @name AuthPasswordResetCreate
   * @request POST:/api/v1/auth/password_reset
   * @secure
   */
  export namespace AuthPasswordResetCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Email;
    export type RequestHeaders = {};
    export type ResponseBody = Email;
  }

  /**
   * @description An Api View which provides a method to reset a password based on a unique token
   * @tags auth
   * @name AuthPasswordResetConfirmCreate
   * @request POST:/api/v1/auth/password_reset/confirm
   */
  export namespace AuthPasswordResetConfirmCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordToken;
    export type RequestHeaders = {};
    export type ResponseBody = PasswordToken;
  }

  /**
   * @description An Api View which provides a method to verify that a token is valid
   * @tags auth
   * @name AuthPasswordResetValidateTokenCreate
   * @request POST:/api/v1/auth/password_reset/validate_token
   */
  export namespace AuthPasswordResetValidateTokenCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResetToken;
    export type RequestHeaders = {};
    export type ResponseBody = ResetToken;
  }

  /**
   * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   * @tags auth
   * @name AuthRefreshTokenCreate
   * @request POST:/api/v1/auth/refresh_token
   */
  export namespace AuthRefreshTokenCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenRefresh;
    export type RequestHeaders = {};
    export type ResponseBody = TokenRefresh;
  }

  /**
   * No description
   * @tags auth
   * @name AuthRegisterCreate
   * @request POST:/api/v1/auth/register
   * @secure
   */
  export namespace AuthRegisterCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Registration;
    export type RequestHeaders = {};
    export type ResponseBody = Registration;
  }

  /**
   * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
   * @tags auth
   * @name AuthTokenVerifyCreate
   * @request POST:/api/v1/auth/token/verify
   */
  export namespace AuthTokenVerifyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenVerify;
    export type RequestHeaders = {};
    export type ResponseBody = TokenVerify;
  }

  /**
   * @description Strona główna bloga - lista kategorii z 3 postami w każdej
   * @tags blog
   * @name BlogRetrieve
   * @request GET:/api/v1/blog
   * @secure
   */
  export namespace BlogRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BlogMainPage;
  }

  /**
   * @description Strona kategorii bloga - postry zaciągamy z search
   * @tags blog
   * @name BlogCategoryRetrieve
   * @request GET:/api/v1/blog/category/{category_slug}
   * @secure
   */
  export namespace BlogCategoryRetrieve {
    export type RequestParams = {
      categorySlug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BlogCategory;
  }

  /**
   * No description
   * @tags blog
   * @name BlogCategoryPostRetrieve
   * @request GET:/api/v1/blog/category/{category_slug}/post/{post_slug}
   * @secure
   */
  export namespace BlogCategoryPostRetrieve {
    export type RequestParams = {
      categorySlug: string;
      postSlug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Post;
  }

  /**
   * No description
   * @tags blog
   * @name BlogSearchList
   * @request GET:/api/v1/blog/search
   * @secure
   */
  export namespace BlogSearchList {
    export type RequestParams = {};
    export type RequestQuery = {
      category_slug?: string;
      /** Number of results to return per page. */
      limit?: number;
      /** The initial index from which to return the results. */
      offset?: number;
      q?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedPostListList;
  }

  /**
   * No description
   * @tags car_search_bar
   * @name CarSearchBarMakesList
   * @request GET:/api/v1/car_search_bar/makes
   * @secure
   */
  export namespace CarSearchBarMakesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Make[];
  }

  /**
   * No description
   * @tags car_search_bar
   * @name CarSearchBarMakesModelsList
   * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models
   * @secure
   */
  export namespace CarSearchBarMakesModelsList {
    export type RequestParams = {
      makeSlug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Model[];
  }

  /**
   * No description
   * @tags car_search_bar
   * @name CarSearchBarMakesModelsGenerationsList
   * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations
   * @secure
   */
  export namespace CarSearchBarMakesModelsGenerationsList {
    export type RequestParams = {
      makeSlug: string;
      modelSlug: string;
      year: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Generation[];
  }

  /**
   * No description
   * @tags car_search_bar
   * @name CarSearchBarMakesModelsGenerationsEnginesList
   * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations/{generation_slug}/engines
   * @secure
   */
  export namespace CarSearchBarMakesModelsGenerationsEnginesList {
    export type RequestParams = {
      generationSlug: string;
      makeSlug: string;
      modelSlug: string;
      year: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Engine[];
  }

  /**
   * No description
   * @tags car_search_bar
   * @name CarSearchBarMakesModelsGenerationsEnginesGetSizesRetrieve
   * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations/{generation_slug}/engines/{modification_slug}/get_sizes
   * @secure
   */
  export namespace CarSearchBarMakesModelsGenerationsEnginesGetSizesRetrieve {
    export type RequestParams = {
      generationSlug: string;
      makeSlug: string;
      modelSlug: string;
      modificationSlug: string;
      year: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SizeByCar;
  }

  /**
   * No description
   * @tags checkout
   * @name CheckoutCreate
   * @request POST:/api/v1/checkout
   * @secure
   */
  export namespace CheckoutCreate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format uuid */
      token?: string;
    };
    export type RequestBody = CreateCheckout;
    export type RequestHeaders = {};
    export type ResponseBody = Checkout;
  }

  /**
   * No description
   * @tags checkout
   * @name CheckoutRetrieve
   * @request GET:/api/v1/checkout/{token}
   * @secure
   */
  export namespace CheckoutRetrieve {
    export type RequestParams = {
      /** @format uuid */
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Checkout;
  }

  /**
   * No description
   * @tags checkout
   * @name CheckoutSimpleRetrieve
   * @request GET:/api/v1/checkout/{token}/simple
   * @secure
   */
  export namespace CheckoutSimpleRetrieve {
    export type RequestParams = {
      /** @format uuid */
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SimpleCheckout;
  }

  /**
   * No description
   * @tags checkout
   * @name CheckoutPaymentsMethodsList
   * @request GET:/api/v1/checkout/payments/methods
   * @secure
   */
  export namespace CheckoutPaymentsMethodsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentMethod[];
  }

  /**
   * No description
   * @tags core
   * @name CoreRedirectsList
   * @request GET:/api/v1/core/redirects
   * @secure
   */
  export namespace CoreRedirectsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Redirect[];
  }

  /**
   * @description Wyszukuje informacje w API REGON używając numeru NIP
   * @tags core
   * @name CoreRegonApiSearchByNipRetrieve
   * @request GET:/api/v1/core/regon_api/search_by_nip/{nip}
   * @secure
   */
  export namespace CoreRegonApiSearchByNipRetrieve {
    export type RequestParams = {
      nip: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RegonAPISearchResult;
  }

  /**
   * No description
   * @tags core
   * @name CoreSettingsRetrieve
   * @request GET:/api/v1/core/settings
   * @secure
   */
  export namespace CoreSettingsRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Settings;
  }

  /**
   * No description
   * @tags core
   * @name CoreSitemapList
   * @request GET:/api/v1/core/sitemap
   * @secure
   */
  export namespace CoreSitemapList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GroupSiteMap[];
  }

  /**
   * No description
   * @tags core
   * @name CoreSitemapCarsList
   * @request GET:/api/v1/core/sitemap_cars
   * @secure
   */
  export namespace CoreSitemapCarsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GroupSiteMap[];
  }

  /**
   * No description
   * @tags core
   * @name CoreSitemapProducerList
   * @request GET:/api/v1/core/sitemap_producer
   * @secure
   */
  export namespace CoreSitemapProducerList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GroupSiteMap[];
  }

  /**
   * No description
   * @tags core
   * @name CoreStaticPagesRetrieve
   * @request GET:/api/v1/core/static_pages/{slug}
   * @secure
   */
  export namespace CoreStaticPagesRetrieve {
    export type RequestParams = {
      slug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StaticPage;
  }

  /**
   * No description
   * @tags main_page
   * @name MainPageRetrieve
   * @request GET:/api/v1/main_page
   * @secure
   */
  export namespace MainPageRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MainPage;
  }

  /**
   * No description
   * @tags main_page
   * @name MainPageBannersList
   * @request GET:/api/v1/main_page/banners
   * @secure
   */
  export namespace MainPageBannersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MainPageBanner[];
  }

  /**
   * No description
   * @tags main_page
   * @name MainPageFooterRetrieve
   * @request GET:/api/v1/main_page/footer
   * @secure
   */
  export namespace MainPageFooterRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Footer;
  }

  /**
   * No description
   * @tags main_page
   * @name MainPageMenuRetrieve
   * @request GET:/api/v1/main_page/menu
   * @secure
   */
  export namespace MainPageMenuRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Menu;
  }

  /**
   * No description
   * @tags main_page
   * @name MainPageMenuSummerRetrieve
   * @request GET:/api/v1/main_page/menu_summer
   * @secure
   */
  export namespace MainPageMenuSummerRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MenuSummer;
  }

  /**
   * No description
   * @tags main_page
   * @name MainPageMenuWinterRetrieve
   * @request GET:/api/v1/main_page/menu_winter
   * @secure
   */
  export namespace MainPageMenuWinterRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MenuWinter;
  }

  /**
   * No description
   * @tags orders
   * @name OrdersCreate
   * @request POST:/api/v1/orders
   * @secure
   */
  export namespace OrdersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrder;
    export type RequestHeaders = {};
    export type ResponseBody = CreateOrderResponse;
  }

  /**
   * No description
   * @tags orders
   * @name OrdersRetrieve
   * @request GET:/api/v1/orders/{id}
   * @secure
   */
  export namespace OrdersRetrieve {
    export type RequestParams = {
      /** @format uuid */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Order;
  }

  /**
   * No description
   * @tags orders
   * @name OrdersPaymentsNotificationCreate
   * @request POST:/api/v1/orders/payments/notification/{token}
   * @secure
   */
  export namespace OrdersPaymentsNotificationCreate {
    export type RequestParams = {
      /** @format uuid */
      token: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags tyre_search_bar
   * @name TyreSearchBarCategoryFiltersList
   * @request GET:/api/v1/tyre_search_bar/category/filters
   * @secure
   */
  export namespace TyreSearchBarCategoryFiltersList {
    export type RequestParams = {};
    export type RequestQuery = {
      category?: string;
      /**
       * @format decimal
       * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
       */
      cena_max?: string;
      /**
       * @format decimal
       * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
       */
      cena_min?: string;
      ean?: string;
      /** Multiple values may be separated by commas. */
      homologacja?: string[];
      /** Multiple values may be separated by commas. */
      indeks_nosnosci?: string[];
      /** Multiple values may be separated by commas. */
      indeks_predkosci?: string[];
      /** Multiple values may be separated by commas. */
      klasa?: string[];
      /** Multiple values may be separated by commas. */
      klasa_halasu?: string[];
      model?: string;
      /** Multiple values may be separated by commas. */
      opor_toczenia?: string[];
      /** @minLength 1 */
      path: string;
      /** Multiple values may be separated by commas. */
      poziom_halasu?: string[];
      /** Multiple values may be separated by commas. */
      producent?: string[];
      profil?: string;
      promocja?: string;
      /** Multiple values may be separated by commas. */
      przyczepnosc_na_mokrej_nawierzchni?: string[];
      /** Multiple values may be separated by commas. */
      rok_produkcji?: string[];
      rozmiar?: string;
      run_flat?: boolean;
      /** Multiple values may be separated by commas. */
      sezon?: string[];
      srednica?: string;
      szerokosc?: string;
      szybka_dostawa?: boolean;
      /** Multiple values may be separated by commas. */
      typ_opony?: string[];
      wzmocnienie_xl?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SearchBarFilters[];
  }

  /**
   * No description
   * @tags tyre_search_bar
   * @name TyreSearchBarCategoryFiltersRetrieve
   * @request GET:/api/v1/tyre_search_bar/category_filters
   * @secure
   */
  export namespace TyreSearchBarCategoryFiltersRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CategoryFilters;
  }

  /**
   * No description
   * @tags tyre_search_bar
   * @name TyreSearchBarFiltersList
   * @request GET:/api/v1/tyre_search_bar/filters
   * @secure
   */
  export namespace TyreSearchBarFiltersList {
    export type RequestParams = {};
    export type RequestQuery = {
      category?: string;
      /**
       * @format decimal
       * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
       */
      cena_max?: string;
      /**
       * @format decimal
       * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
       */
      cena_min?: string;
      ean?: string;
      /** Multiple values may be separated by commas. */
      homologacja?: string[];
      /** Multiple values may be separated by commas. */
      indeks_nosnosci?: string[];
      /** Multiple values may be separated by commas. */
      indeks_predkosci?: string[];
      /** Multiple values may be separated by commas. */
      klasa?: string[];
      /** Multiple values may be separated by commas. */
      klasa_halasu?: string[];
      model?: string;
      /** Multiple values may be separated by commas. */
      opor_toczenia?: string[];
      /** Multiple values may be separated by commas. */
      poziom_halasu?: string[];
      /** Multiple values may be separated by commas. */
      producent?: string[];
      profil?: string;
      promocja?: string;
      /** Multiple values may be separated by commas. */
      przyczepnosc_na_mokrej_nawierzchni?: string[];
      /** Multiple values may be separated by commas. */
      rok_produkcji?: string[];
      rozmiar?: string;
      run_flat?: boolean;
      /** Multiple values may be separated by commas. */
      sezon?: string[];
      srednica?: string;
      szerokosc?: string;
      szybka_dostawa?: boolean;
      /** Multiple values may be separated by commas. */
      typ_opony?: string[];
      wzmocnienie_xl?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SearchBarFilters[];
  }

  /**
   * No description
   * @tags tyre_search_bar
   * @name TyreSearchBarSizesRetrieve
   * @request GET:/api/v1/tyre_search_bar/sizes
   * @secure
   */
  export namespace TyreSearchBarSizesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Sizes;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresCarsRetrieve
   * @request GET:/api/v1/tyres/cars
   * @secure
   */
  export namespace TyresCarsRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CarCategory;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresCarsRetrieve2
   * @request GET:/api/v1/tyres/cars/{make_slug}
   * @secure
   */
  export namespace TyresCarsRetrieve2 {
    export type RequestParams = {
      makeSlug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MakeCategory;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresCarsRetrieve3
   * @request GET:/api/v1/tyres/cars/{make_slug}/{model_slug}
   * @secure
   */
  export namespace TyresCarsRetrieve3 {
    export type RequestParams = {
      makeSlug: string;
      modelSlug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelCategory;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresCategoryRetrieve
   * @request GET:/api/v1/tyres/category
   * @secure
   */
  export namespace TyresCategoryRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      limit?: number;
      offset?: number;
      /** @minLength 1 */
      path: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Category;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresCompareList
   * @request GET:/api/v1/tyres/compare
   * @secure
   */
  export namespace TyresCompareList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** SKU identifiers separated by commas. A maximum of 3 SKUs can be compared. */
      sku?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ListingVariant[];
  }

  /**
   * No description
   * @tags tyres
   * @name TyresListingBannersList
   * @request GET:/api/v1/tyres/listing_banners
   * @secure
   */
  export namespace TyresListingBannersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ListingBanner[];
  }

  /**
   * No description
   * @tags tyres
   * @name TyresModelRetrieve
   * @request GET:/api/v1/tyres/model
   * @secure
   */
  export namespace TyresModelRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @minLength 1 */
      path: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TyreModelResponse;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresProductSalesRetrieve
   * @request GET:/api/v1/tyres/product/{sku}/sales
   * @secure
   */
  export namespace TyresProductSalesRetrieve {
    export type RequestParams = {
      sku: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductSales;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresProductRetrieve
   * @request GET:/api/v1/tyres/product/{slug}
   * @secure
   */
  export namespace TyresProductRetrieve {
    export type RequestParams = {
      slug: string;
    };
    export type RequestQuery = {
      /** @minLength 1 */
      sup?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Variant;
  }

  /**
   * @description Handles product inquiries by sending an email with product details and a customer message.
   * @tags tyres
   * @name TyresProductAskCreate
   * @request POST:/api/v1/tyres/product/{slug}/ask
   * @secure
   */
  export namespace TyresProductAskCreate {
    export type RequestParams = {
      slug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = ProductInquiry;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresReviewsList
   * @request GET:/api/v1/tyres/reviews
   * @secure
   */
  export namespace TyresReviewsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Number of results to return per page. */
      limit?: number;
      /** The initial index from which to return the results. */
      offset?: number;
      producer__name?: string;
      product__ean?: string;
      product_slug?: string;
      tread__name?: string;
      tread_slug?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedReviewList;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresReviewsRetrieve
   * @request GET:/api/v1/tyres/reviews/{order_line_id}
   * @secure
   */
  export namespace TyresReviewsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      orderLineId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductReview;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresReviewsCreate
   * @request POST:/api/v1/tyres/reviews/{order_line_id}
   * @secure
   */
  export namespace TyresReviewsCreate {
    export type RequestParams = {
      /** @format uuid */
      orderLineId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = ReviewCreate;
    export type RequestHeaders = {};
    export type ResponseBody = ReviewCreate;
  }

  /**
   * No description
   * @tags tyres
   * @name TyresSearchList
   * @request GET:/api/v1/tyres/search
   * @secure
   */
  export namespace TyresSearchList {
    export type RequestParams = {};
    export type RequestQuery = {
      category?: string;
      /**
       * @format decimal
       * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
       */
      cena_max?: string;
      /**
       * @format decimal
       * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
       */
      cena_min?: string;
      ean?: string;
      /** Multiple values may be separated by commas. */
      homologacja?: string[];
      /** Multiple values may be separated by commas. */
      indeks_nosnosci?: string[];
      /** Multiple values may be separated by commas. */
      indeks_predkosci?: string[];
      /** Multiple values may be separated by commas. */
      klasa?: string[];
      /** Multiple values may be separated by commas. */
      klasa_halasu?: string[];
      /** Number of results to return per page. */
      limit?: number;
      model?: string;
      /** The initial index from which to return the results. */
      offset?: number;
      /** Multiple values may be separated by commas. */
      opor_toczenia?: string[];
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Multiple values may be separated by commas. */
      poziom_halasu?: string[];
      /** Multiple values may be separated by commas. */
      producent?: string[];
      profil?: string;
      promocja?: string;
      /** Multiple values may be separated by commas. */
      przyczepnosc_na_mokrej_nawierzchni?: string[];
      /** Multiple values may be separated by commas. */
      rok_produkcji?: string[];
      rozmiar?: string;
      run_flat?: boolean;
      /** Multiple values may be separated by commas. */
      sezon?: string[];
      srednica?: string;
      szerokosc?: string;
      szybka_dostawa?: boolean;
      /** Multiple values may be separated by commas. */
      typ_opony?: string[];
      wzmocnienie_xl?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaginatedListingVariantList;
  }
}

export namespace Schema {
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/schema/
   * @secure
   */
  export namespace SchemaRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      format?: 'json' | 'yaml';
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Your Project API
 * @version 1.0.0
 *
 * Your project description
 */
export class AxiosApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags account
     * @name AccountOrdersList
     * @request GET:/api/v1/account/orders
     * @secure
     */
    accountOrdersList: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedUserOrderListList, any>({
        path: `/api/v1/account/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountOrdersRetrieve
     * @request GET:/api/v1/account/orders/{id}
     * @secure
     */
    accountOrdersRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<UserOrderDetail, any>({
        path: `/api/v1/account/orders/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountOrdersInvoiceRetrieve
     * @request GET:/api/v1/account/orders/{id}/invoice
     * @secure
     */
    accountOrdersInvoiceRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/account/orders/${id}/invoice`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags account
     * @name AccountUserRetrieve
     * @request GET:/api/v1/account/user
     * @secure
     */
    accountUserRetrieve: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/account/user`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Zmiana hasła użytkownika.
     *
     * @tags account
     * @name AccountUserChangePasswordCreate
     * @request POST:/api/v1/account/user/change_password
     * @secure
     */
    accountUserChangePasswordCreate: (data: ChangePassword, params: RequestParams = {}) =>
      this.request<
        void,
        {
          error_code?: 'INVALID_OLD_PASSWORD';
          /** @example "Old password is invalid." */
          message?: string;
        }
      >({
        path: `/api/v1/account/user/change_password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Usuwanie konta użytkownika.
     *
     * @tags account
     * @name AccountUserDeleteAccountDestroy
     * @request DELETE:/api/v1/account/user/delete_account
     * @secure
     */
    accountUserDeleteAccountDestroy: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/account/user/delete_account`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Aktualizacja profilu użytkownika.
     *
     * @tags account
     * @name AccountUserEditProfileUpdate
     * @request PUT:/api/v1/account/user/edit_profile
     * @secure
     */
    accountUserEditProfileUpdate: (data: UserEdit, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/account/user/edit_profile`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     *
     * @tags auth
     * @name AuthLoginCreate
     * @request POST:/api/v1/auth/login
     */
    authLoginCreate: (data: TokenObtainPair, params: RequestParams = {}) =>
      this.request<TokenObtainPair, any>({
        path: `/api/v1/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Request a password reset link to be sent to the provided email.
     *
     * @tags auth
     * @name AuthPasswordResetCreate
     * @request POST:/api/v1/auth/password_reset
     * @secure
     */
    authPasswordResetCreate: (data: Email, params: RequestParams = {}) =>
      this.request<
        Email,
        {
          error_code?: 'USER_DOES_NOT_EXIST';
          /** @example "User does not exist" */
          message?: string;
        }
      >({
        path: `/api/v1/auth/password_reset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description An Api View which provides a method to reset a password based on a unique token
     *
     * @tags auth
     * @name AuthPasswordResetConfirmCreate
     * @request POST:/api/v1/auth/password_reset/confirm
     */
    authPasswordResetConfirmCreate: (data: PasswordToken, params: RequestParams = {}) =>
      this.request<PasswordToken, any>({
        path: `/api/v1/auth/password_reset/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description An Api View which provides a method to verify that a token is valid
     *
     * @tags auth
     * @name AuthPasswordResetValidateTokenCreate
     * @request POST:/api/v1/auth/password_reset/validate_token
     */
    authPasswordResetValidateTokenCreate: (data: ResetToken, params: RequestParams = {}) =>
      this.request<ResetToken, any>({
        path: `/api/v1/auth/password_reset/validate_token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags auth
     * @name AuthRefreshTokenCreate
     * @request POST:/api/v1/auth/refresh_token
     */
    authRefreshTokenCreate: (data: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/api/v1/auth/refresh_token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterCreate
     * @request POST:/api/v1/auth/register
     * @secure
     */
    authRegisterCreate: (data: Registration, params: RequestParams = {}) =>
      this.request<
        Registration,
        {
          error_code?: 'EMAIL_ALREADY_EXISTS';
          /** @example "Email already exists." */
          message?: string;
        }
      >({
        path: `/api/v1/auth/register`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
     *
     * @tags auth
     * @name AuthTokenVerifyCreate
     * @request POST:/api/v1/auth/token/verify
     */
    authTokenVerifyCreate: (data: TokenVerify, params: RequestParams = {}) =>
      this.request<TokenVerify, any>({
        path: `/api/v1/auth/token/verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Strona główna bloga - lista kategorii z 3 postami w każdej
     *
     * @tags blog
     * @name BlogRetrieve
     * @request GET:/api/v1/blog
     * @secure
     */
    blogRetrieve: (params: RequestParams = {}) =>
      this.request<BlogMainPage, any>({
        path: `/api/v1/blog`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Strona kategorii bloga - postry zaciągamy z search
     *
     * @tags blog
     * @name BlogCategoryRetrieve
     * @request GET:/api/v1/blog/category/{category_slug}
     * @secure
     */
    blogCategoryRetrieve: (categorySlug: string, params: RequestParams = {}) =>
      this.request<BlogCategory, any>({
        path: `/api/v1/blog/category/${categorySlug}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog
     * @name BlogCategoryPostRetrieve
     * @request GET:/api/v1/blog/category/{category_slug}/post/{post_slug}
     * @secure
     */
    blogCategoryPostRetrieve: (categorySlug: string, postSlug: string, params: RequestParams = {}) =>
      this.request<Post, any>({
        path: `/api/v1/blog/category/${categorySlug}/post/${postSlug}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog
     * @name BlogSearchList
     * @request GET:/api/v1/blog/search
     * @secure
     */
    blogSearchList: (
      query?: {
        category_slug?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        q?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedPostListList, any>({
        path: `/api/v1/blog/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags car_search_bar
     * @name CarSearchBarMakesList
     * @request GET:/api/v1/car_search_bar/makes
     * @secure
     */
    carSearchBarMakesList: (params: RequestParams = {}) =>
      this.request<Make[], any>({
        path: `/api/v1/car_search_bar/makes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags car_search_bar
     * @name CarSearchBarMakesModelsList
     * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models
     * @secure
     */
    carSearchBarMakesModelsList: (makeSlug: string, params: RequestParams = {}) =>
      this.request<Model[], any>({
        path: `/api/v1/car_search_bar/makes/${makeSlug}/models`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags car_search_bar
     * @name CarSearchBarMakesModelsGenerationsList
     * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations
     * @secure
     */
    carSearchBarMakesModelsGenerationsList: (
      makeSlug: string,
      modelSlug: string,
      year: string,
      params: RequestParams = {},
    ) =>
      this.request<Generation[], any>({
        path: `/api/v1/car_search_bar/makes/${makeSlug}/models/${modelSlug}/${year}/generations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags car_search_bar
     * @name CarSearchBarMakesModelsGenerationsEnginesList
     * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations/{generation_slug}/engines
     * @secure
     */
    carSearchBarMakesModelsGenerationsEnginesList: (
      generationSlug: string,
      makeSlug: string,
      modelSlug: string,
      year: string,
      params: RequestParams = {},
    ) =>
      this.request<Engine[], any>({
        path: `/api/v1/car_search_bar/makes/${makeSlug}/models/${modelSlug}/${year}/generations/${generationSlug}/engines`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags car_search_bar
     * @name CarSearchBarMakesModelsGenerationsEnginesGetSizesRetrieve
     * @request GET:/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations/{generation_slug}/engines/{modification_slug}/get_sizes
     * @secure
     */
    carSearchBarMakesModelsGenerationsEnginesGetSizesRetrieve: (
      generationSlug: string,
      makeSlug: string,
      modelSlug: string,
      modificationSlug: string,
      year: string,
      params: RequestParams = {},
    ) =>
      this.request<SizeByCar, any>({
        path: `/api/v1/car_search_bar/makes/${makeSlug}/models/${modelSlug}/${year}/generations/${generationSlug}/engines/${modificationSlug}/get_sizes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags checkout
     * @name CheckoutCreate
     * @request POST:/api/v1/checkout
     * @secure
     */
    checkoutCreate: (
      data: CreateCheckout,
      query?: {
        /** @format uuid */
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        Checkout,
        {
          /** @example "VARIANT_SOLD_OUT" */
          error_code?: string;
          /** @example "Produkt został wyprzedany. Odświeżyć stronę." */
          message?: string;
        }
      >({
        path: `/api/v1/checkout`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags checkout
     * @name CheckoutRetrieve
     * @request GET:/api/v1/checkout/{token}
     * @secure
     */
    checkoutRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<Checkout, any>({
        path: `/api/v1/checkout/${token}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags checkout
     * @name CheckoutSimpleRetrieve
     * @request GET:/api/v1/checkout/{token}/simple
     * @secure
     */
    checkoutSimpleRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<SimpleCheckout, any>({
        path: `/api/v1/checkout/${token}/simple`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags checkout
     * @name CheckoutPaymentsMethodsList
     * @request GET:/api/v1/checkout/payments/methods
     * @secure
     */
    checkoutPaymentsMethodsList: (params: RequestParams = {}) =>
      this.request<PaymentMethod[], any>({
        path: `/api/v1/checkout/payments/methods`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreRedirectsList
     * @request GET:/api/v1/core/redirects
     * @secure
     */
    coreRedirectsList: (params: RequestParams = {}) =>
      this.request<Redirect[], any>({
        path: `/api/v1/core/redirects`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Wyszukuje informacje w API REGON używając numeru NIP
     *
     * @tags core
     * @name CoreRegonApiSearchByNipRetrieve
     * @request GET:/api/v1/core/regon_api/search_by_nip/{nip}
     * @secure
     */
    coreRegonApiSearchByNipRetrieve: (nip: string, params: RequestParams = {}) =>
      this.request<RegonAPISearchResult, any>({
        path: `/api/v1/core/regon_api/search_by_nip/${nip}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreSettingsRetrieve
     * @request GET:/api/v1/core/settings
     * @secure
     */
    coreSettingsRetrieve: (params: RequestParams = {}) =>
      this.request<Settings, any>({
        path: `/api/v1/core/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreSitemapList
     * @request GET:/api/v1/core/sitemap
     * @secure
     */
    coreSitemapList: (params: RequestParams = {}) =>
      this.request<GroupSiteMap[], any>({
        path: `/api/v1/core/sitemap`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreSitemapCarsList
     * @request GET:/api/v1/core/sitemap_cars
     * @secure
     */
    coreSitemapCarsList: (params: RequestParams = {}) =>
      this.request<GroupSiteMap[], any>({
        path: `/api/v1/core/sitemap_cars`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreSitemapProducerList
     * @request GET:/api/v1/core/sitemap_producer
     * @secure
     */
    coreSitemapProducerList: (params: RequestParams = {}) =>
      this.request<GroupSiteMap[], any>({
        path: `/api/v1/core/sitemap_producer`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags core
     * @name CoreStaticPagesRetrieve
     * @request GET:/api/v1/core/static_pages/{slug}
     * @secure
     */
    coreStaticPagesRetrieve: (slug: string, params: RequestParams = {}) =>
      this.request<StaticPage, any>({
        path: `/api/v1/core/static_pages/${slug}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags main_page
     * @name MainPageRetrieve
     * @request GET:/api/v1/main_page
     * @secure
     */
    mainPageRetrieve: (params: RequestParams = {}) =>
      this.request<MainPage, any>({
        path: `/api/v1/main_page`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags main_page
     * @name MainPageBannersList
     * @request GET:/api/v1/main_page/banners
     * @secure
     */
    mainPageBannersList: (params: RequestParams = {}) =>
      this.request<MainPageBanner[], any>({
        path: `/api/v1/main_page/banners`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags main_page
     * @name MainPageFooterRetrieve
     * @request GET:/api/v1/main_page/footer
     * @secure
     */
    mainPageFooterRetrieve: (params: RequestParams = {}) =>
      this.request<Footer, any>({
        path: `/api/v1/main_page/footer`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags main_page
     * @name MainPageMenuRetrieve
     * @request GET:/api/v1/main_page/menu
     * @secure
     */
    mainPageMenuRetrieve: (params: RequestParams = {}) =>
      this.request<Menu, any>({
        path: `/api/v1/main_page/menu`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags main_page
     * @name MainPageMenuSummerRetrieve
     * @request GET:/api/v1/main_page/menu_summer
     * @secure
     */
    mainPageMenuSummerRetrieve: (params: RequestParams = {}) =>
      this.request<MenuSummer, any>({
        path: `/api/v1/main_page/menu_summer`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags main_page
     * @name MainPageMenuWinterRetrieve
     * @request GET:/api/v1/main_page/menu_winter
     * @secure
     */
    mainPageMenuWinterRetrieve: (params: RequestParams = {}) =>
      this.request<MenuWinter, any>({
        path: `/api/v1/main_page/menu_winter`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags orders
     * @name OrdersCreate
     * @request POST:/api/v1/orders
     * @secure
     */
    ordersCreate: (data: CreateOrder, params: RequestParams = {}) =>
      this.request<
        CreateOrderResponse,
        {
          /** @example "VARIANT_SOLD_OUT" */
          error_code?: string;
          /** @example ["Niektóre produkty zostały wyprzedane. Proszę odświeżyć koszyk."] */
          message?: string;
        }
      >({
        path: `/api/v1/orders`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags orders
     * @name OrdersRetrieve
     * @request GET:/api/v1/orders/{id}
     * @secure
     */
    ordersRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<Order, any>({
        path: `/api/v1/orders/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags orders
     * @name OrdersPaymentsNotificationCreate
     * @request POST:/api/v1/orders/payments/notification/{token}
     * @secure
     */
    ordersPaymentsNotificationCreate: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/orders/payments/notification/${token}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyre_search_bar
     * @name TyreSearchBarCategoryFiltersList
     * @request GET:/api/v1/tyre_search_bar/category/filters
     * @secure
     */
    tyreSearchBarCategoryFiltersList: (
      query: {
        category?: string;
        /**
         * @format decimal
         * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
         */
        cena_max?: string;
        /**
         * @format decimal
         * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
         */
        cena_min?: string;
        ean?: string;
        /** Multiple values may be separated by commas. */
        homologacja?: string[];
        /** Multiple values may be separated by commas. */
        indeks_nosnosci?: string[];
        /** Multiple values may be separated by commas. */
        indeks_predkosci?: string[];
        /** Multiple values may be separated by commas. */
        klasa?: string[];
        /** Multiple values may be separated by commas. */
        klasa_halasu?: string[];
        model?: string;
        /** Multiple values may be separated by commas. */
        opor_toczenia?: string[];
        /** @minLength 1 */
        path: string;
        /** Multiple values may be separated by commas. */
        poziom_halasu?: string[];
        /** Multiple values may be separated by commas. */
        producent?: string[];
        profil?: string;
        promocja?: string;
        /** Multiple values may be separated by commas. */
        przyczepnosc_na_mokrej_nawierzchni?: string[];
        /** Multiple values may be separated by commas. */
        rok_produkcji?: string[];
        rozmiar?: string;
        run_flat?: boolean;
        /** Multiple values may be separated by commas. */
        sezon?: string[];
        srednica?: string;
        szerokosc?: string;
        szybka_dostawa?: boolean;
        /** Multiple values may be separated by commas. */
        typ_opony?: string[];
        wzmocnienie_xl?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchBarFilters[], any>({
        path: `/api/v1/tyre_search_bar/category/filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyre_search_bar
     * @name TyreSearchBarCategoryFiltersRetrieve
     * @request GET:/api/v1/tyre_search_bar/category_filters
     * @secure
     */
    tyreSearchBarCategoryFiltersRetrieve: (params: RequestParams = {}) =>
      this.request<CategoryFilters, any>({
        path: `/api/v1/tyre_search_bar/category_filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyre_search_bar
     * @name TyreSearchBarFiltersList
     * @request GET:/api/v1/tyre_search_bar/filters
     * @secure
     */
    tyreSearchBarFiltersList: (
      query?: {
        category?: string;
        /**
         * @format decimal
         * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
         */
        cena_max?: string;
        /**
         * @format decimal
         * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
         */
        cena_min?: string;
        ean?: string;
        /** Multiple values may be separated by commas. */
        homologacja?: string[];
        /** Multiple values may be separated by commas. */
        indeks_nosnosci?: string[];
        /** Multiple values may be separated by commas. */
        indeks_predkosci?: string[];
        /** Multiple values may be separated by commas. */
        klasa?: string[];
        /** Multiple values may be separated by commas. */
        klasa_halasu?: string[];
        model?: string;
        /** Multiple values may be separated by commas. */
        opor_toczenia?: string[];
        /** Multiple values may be separated by commas. */
        poziom_halasu?: string[];
        /** Multiple values may be separated by commas. */
        producent?: string[];
        profil?: string;
        promocja?: string;
        /** Multiple values may be separated by commas. */
        przyczepnosc_na_mokrej_nawierzchni?: string[];
        /** Multiple values may be separated by commas. */
        rok_produkcji?: string[];
        rozmiar?: string;
        run_flat?: boolean;
        /** Multiple values may be separated by commas. */
        sezon?: string[];
        srednica?: string;
        szerokosc?: string;
        szybka_dostawa?: boolean;
        /** Multiple values may be separated by commas. */
        typ_opony?: string[];
        wzmocnienie_xl?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchBarFilters[], any>({
        path: `/api/v1/tyre_search_bar/filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyre_search_bar
     * @name TyreSearchBarSizesRetrieve
     * @request GET:/api/v1/tyre_search_bar/sizes
     * @secure
     */
    tyreSearchBarSizesRetrieve: (params: RequestParams = {}) =>
      this.request<Sizes, any>({
        path: `/api/v1/tyre_search_bar/sizes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresCarsRetrieve
     * @request GET:/api/v1/tyres/cars
     * @secure
     */
    tyresCarsRetrieve: (params: RequestParams = {}) =>
      this.request<CarCategory, any>({
        path: `/api/v1/tyres/cars`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresCarsRetrieve2
     * @request GET:/api/v1/tyres/cars/{make_slug}
     * @secure
     */
    tyresCarsRetrieve2: (makeSlug: string, params: RequestParams = {}) =>
      this.request<MakeCategory, any>({
        path: `/api/v1/tyres/cars/${makeSlug}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresCarsRetrieve3
     * @request GET:/api/v1/tyres/cars/{make_slug}/{model_slug}
     * @secure
     */
    tyresCarsRetrieve3: (makeSlug: string, modelSlug: string, params: RequestParams = {}) =>
      this.request<ModelCategory, any>({
        path: `/api/v1/tyres/cars/${makeSlug}/${modelSlug}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresCategoryRetrieve
     * @request GET:/api/v1/tyres/category
     * @secure
     */
    tyresCategoryRetrieve: (
      query: {
        limit?: number;
        offset?: number;
        /** @minLength 1 */
        path: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Category, any>({
        path: `/api/v1/tyres/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresCompareList
     * @request GET:/api/v1/tyres/compare
     * @secure
     */
    tyresCompareList: (
      query?: {
        /** SKU identifiers separated by commas. A maximum of 3 SKUs can be compared. */
        sku?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ListingVariant[], any>({
        path: `/api/v1/tyres/compare`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresListingBannersList
     * @request GET:/api/v1/tyres/listing_banners
     * @secure
     */
    tyresListingBannersList: (params: RequestParams = {}) =>
      this.request<ListingBanner[], any>({
        path: `/api/v1/tyres/listing_banners`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresModelRetrieve
     * @request GET:/api/v1/tyres/model
     * @secure
     */
    tyresModelRetrieve: (
      query: {
        /** @minLength 1 */
        path: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TyreModelResponse, any>({
        path: `/api/v1/tyres/model`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresProductSalesRetrieve
     * @request GET:/api/v1/tyres/product/{sku}/sales
     * @secure
     */
    tyresProductSalesRetrieve: (sku: string, params: RequestParams = {}) =>
      this.request<
        ProductSales,
        {
          /** @example "VARIANT_SOLD_OUT" */
          error_code?: string;
          /** @example "Produkt został wyprzedany. Odświeżyć stronę." */
          message?: string;
        }
      >({
        path: `/api/v1/tyres/product/${sku}/sales`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresProductRetrieve
     * @request GET:/api/v1/tyres/product/{slug}
     * @secure
     */
    tyresProductRetrieve: (
      slug: string,
      query?: {
        /** @minLength 1 */
        sup?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Variant, any>({
        path: `/api/v1/tyres/product/${slug}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Handles product inquiries by sending an email with product details and a customer message.
     *
     * @tags tyres
     * @name TyresProductAskCreate
     * @request POST:/api/v1/tyres/product/{slug}/ask
     * @secure
     */
    tyresProductAskCreate: (slug: string, data: ProductInquiry, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/tyres/product/${slug}/ask`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresReviewsList
     * @request GET:/api/v1/tyres/reviews
     * @secure
     */
    tyresReviewsList: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        producer__name?: string;
        product__ean?: string;
        product_slug?: string;
        tread__name?: string;
        tread_slug?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedReviewList, any>({
        path: `/api/v1/tyres/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresReviewsRetrieve
     * @request GET:/api/v1/tyres/reviews/{order_line_id}
     * @secure
     */
    tyresReviewsRetrieve: (orderLineId: string, params: RequestParams = {}) =>
      this.request<ProductReview, void>({
        path: `/api/v1/tyres/reviews/${orderLineId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresReviewsCreate
     * @request POST:/api/v1/tyres/reviews/{order_line_id}
     * @secure
     */
    tyresReviewsCreate: (orderLineId: string, data: ReviewCreate, params: RequestParams = {}) =>
      this.request<
        ReviewCreate,
        | {
            /** @example "MAX_IMAGES" */
            error_code?: string;
            /** @example "You can upload a maximum of 3 images." */
            message?: string;
          }
        | {
            /** @example "WRONG_FILE_FORMAT" */
            error_code?: string;
            /** @example "Only .jpg, .jpeg, .png, .webp formats are allowed." */
            message?: string;
          }
        | {
            /** @example "ENTITY_TOO_LARGE" */
            error_code?: string;
            /** @example "Each image must be smaller than 1 MB after optimization." */
            message?: string;
          }
      >({
        path: `/api/v1/tyres/reviews/${orderLineId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tyres
     * @name TyresSearchList
     * @request GET:/api/v1/tyres/search
     * @secure
     */
    tyresSearchList: (
      query?: {
        category?: string;
        /**
         * @format decimal
         * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
         */
        cena_max?: string;
        /**
         * @format decimal
         * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
         */
        cena_min?: string;
        ean?: string;
        /** Multiple values may be separated by commas. */
        homologacja?: string[];
        /** Multiple values may be separated by commas. */
        indeks_nosnosci?: string[];
        /** Multiple values may be separated by commas. */
        indeks_predkosci?: string[];
        /** Multiple values may be separated by commas. */
        klasa?: string[];
        /** Multiple values may be separated by commas. */
        klasa_halasu?: string[];
        /** Number of results to return per page. */
        limit?: number;
        model?: string;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Multiple values may be separated by commas. */
        opor_toczenia?: string[];
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** Multiple values may be separated by commas. */
        poziom_halasu?: string[];
        /** Multiple values may be separated by commas. */
        producent?: string[];
        profil?: string;
        promocja?: string;
        /** Multiple values may be separated by commas. */
        przyczepnosc_na_mokrej_nawierzchni?: string[];
        /** Multiple values may be separated by commas. */
        rok_produkcji?: string[];
        rozmiar?: string;
        run_flat?: boolean;
        /** Multiple values may be separated by commas. */
        sezon?: string[];
        srednica?: string;
        szerokosc?: string;
        szybka_dostawa?: boolean;
        /** Multiple values may be separated by commas. */
        typ_opony?: string[];
        wzmocnienie_xl?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedListingVariantList, any>({
        path: `/api/v1/tyres/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  schema = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema
     * @name SchemaRetrieve
     * @request GET:/schema/
     * @secure
     */
    schemaRetrieve: (
      query?: {
        format?: 'json' | 'yaml';
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, any>, any>({
        path: `/schema/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
